import LciCard from "./LciCard.vue";
import LogoContainer from "./LogoContainer.vue";
import UserPattern from "./UserPattern.vue";
import AppButton from "./AppButton.vue";
import AppInputLabel from "./AppImputable.vue";
import CustomTable from "./CustomTable.vue";
import SharePrintCard from "./SharePrintCard.vue";
import TableHeaderSection from "./TableHeaderSection.vue";
import DeleteTasks from "./DeleteTasks.vue";

export default [
  LogoContainer,
  LciCard,
  UserPattern,
  AppButton,
  AppInputLabel,
  CustomTable,
  SharePrintCard,
  TableHeaderSection,
  DeleteTasks
];
