<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-center">
      <nav>
        <ul class="footer-menu"></ul>
      </nav>
      <div class="copyright">
        Developed by
        <a href="https://www.vero-as.com/" target="_blank" rel="noopener"
          >VERO</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
