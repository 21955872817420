<template>
  <div class="container-login100">
    <div class="wrap-login100 shadow-lg p-4">
      <el-row style="width: 100%">
        <!-- Brand Logo -->
        <el-col :sm="9">
          <div
            class="
              login100-pic
              js-tilt
              d-flex
              justify-content-center
              align-items-center
              mb-5
            "
            data-tilt
            style="position: relative; top: 50px"
          >
            <img
              src="../../../assets/images/logo/03.png"
              class="logoo"
              alt="IMG"
            />
          </div>
        </el-col>
        <!-- Login Form src="../../../assets/images/logo/03.png"  -->
        <el-col :sm="15">
          <fade-render-transition
            :duration="500"
            class="mt-5 pt-5"
            style="width: 100%"
          >
            <el-form
              v-model="resetPassword"
              :model="resetPassword"
              ref="resetPassRef"
              :rules="resetPassRules"
              style="width: 95%"
              @submit.prevent="submit"
            >
              <div>
                <el-form-item
                  label="Password"
                  prop="password"
                  label-width="140px"
                  style="width: 100%"
                  class="position-relative "
                >
                  <!-- Show Password Icon  -->
                  <i
                    v-if="passwordType == 'password'"
                    class="
                      fa fa-eye
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                    @click="toggleShowPassword"
                    v-tooltip.top-center="'Show Password'"
                    aria-hidden="true"
                  ></i>
                  <i
                    v-if="passwordType == 'text'"
                    class="
                      fa fa-eye-slash
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                    @click="toggleShowPassword"
                    v-tooltip.top-center="'Hide Password'"
                    aria-hidden="true"
                  ></i>
                  <el-input
                    :type="passwordType"
                    v-model="resetPassword.password"
                    placeholder="Please enter Password"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Confirm Password"
                  prop="confirmPassword"
                  label-width="140px"
                  style="width: 100%"
                  class="position-relative "
                >
                  <i
                    v-if="confirmPasswordType == 'password'"
                    class="
                      fa fa-eye
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                    @click="toggleShowConfirmPassword"
                    v-tooltip.top-center="'Show Password'"
                    aria-hidden="true"
                  ></i>
                  <i
                    v-if="confirmPasswordType == 'text'"
                    class="
                      fa fa-eye-slash
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                    @click="toggleShowConfirmPassword"
                    v-tooltip.top-center="'Hide Password'"
                    aria-hidden="true"
                  ></i>
                  <el-input
                    :type="confirmPasswordType"
                    v-model="resetPassword.confirmPassword"
                    placeholder="Please enter Password"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </div>

              <div style="width: 100%" class="mb-3 mt-3">
                <el-button
                  @click="submit"
                  type="info"
                  class="btn btn-fill btn-info d-block m-auto btn-wd "
                >
                  Submit
                </el-button>
              </div>
            </el-form>
          </fade-render-transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { FadeRenderTransition } from "src/components/index";
import Swal from "sweetalert2";
import Button from "../../../components/Button.vue";

export default {
  name: "ResetPassword",
  data() {
    return {
      passwordType: "password",
      confirmPasswordType: "password",
      resetPassword: {},
      resetPassRules: {
        password: [
          {
            required: true,
            message: "Please input password",
            trigger: "blur"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please reenter password",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {
    FadeRenderTransition,
    Button
  },
  mounted() {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     this.submit();
    //   }
    // });
  },
  methods: {
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleShowConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType == "password" ? "text" : "password";
    },
    submit() {
      this.$refs["resetPassRef"].validate(valid => {
        if (valid) {
          //  If passwords does not match
          if (
            this.resetPassword.password !== this.resetPassword.confirmPassword
          ) {
            Swal.fire({
              icon: "error",
              text: "Passwords does not match",
              confirmButtonClass: "btn btn-info btn-fill",
              showConfirmButton: false,
              showCloseButton: true
            });
          } else {
            // call reset password on store
            this.$store.dispatch("user/resetNewPassword", {
              newPasswordData: {
                password: this.resetPassword.password,
                confirm_password: this.resetPassword.confirmPassword,
                token: this.$route.params.token
              }
            });
            // only redirect to login if enter 2 matching passwords
            this.$router.push("/login");
          }
        } else {
          console.log("error submitting");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: url("/static/img/full-screen-image-4.jpg") no-repeat center;
  background-size: cover;
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 50px 20px 50px;
}

.togglePassword {
  z-index: 5;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}

.logoo {
}

@media (min-width: 320px) and (max-width: 767px) {
  ::v-deep .el-form-item__label {
    width: 200px !important;
    text-align: left;
    float: none;
    float: unset;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
