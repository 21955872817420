<template>
  <div class="container-login100 just_for_select_register">
    <div class="wrap-login100 shadow-lg">
      <i @click="$router.go(-1)" class="el-icon-back goo-back shadow-sm "></i>
      <el-row style="width: 100%">
        <!-- Start Logo And Patterns -->
        <el-col :span="24">
          <!-- Start Dropdown Language  -->
          <div class="drop-language">
            <el-select
              class="select-default"
              v-model="selectedLanguageId"
              :placeholder="`${publicLocalization('Language')}`"
              @change="switchLocalization()"
            >
              <el-option
                v-for="item in publishedLanguages"
                :key="item.id"
                :value="item.id"
                :label="item.title"
              >
              </el-option>
            </el-select>
          </div>
          <!-- End Dropdown Language  -->

          <div
            class="
              login100-pic
              js-tilt
              d-flex
              justify-content-center
              align-items-center
              mb-5
            "
            data-tilt
            :class="{ 'mx-auto': true }"
          >
            <!-- <img src="../assets/images/vue-logo.jpg" class="logoo" alt="IMG" /> -->

            <img
              src="../../../assets/images/logo/03.png"
              class="logoo"
              alt="IMG"
            />
          </div>
        </el-col>
        <el-col :span="24">
          <div class="login_what_patterns mb-3 ">
            <div class="text_patterns_box ">
              <span class="pat_text">{{
                publicLocalization("what are your patterns?")
              }}</span>
              <span class="pat_one"></span>
              <span class="pat_two"></span>
              <span class="pat_three"></span>
              <span class="pat_four"></span>
              <span class="pat_five"></span>
              <span class="pat_six"></span>
              <span class="pat_seven"></span>
              <span class="pat_eight"></span>
            </div>
          </div>
        </el-col>
        <!-- End Logo And Patterns -->

        <!--  -->
        <el-col :span="24">
          <fade-render-transition :duration="500">
            <!-- //// START THIS JUST FOR FAMILY -->
            <div>
              <el-form
                v-model="register"
                :model="register"
                label-position="top"
                ref="register_Ref"
                :rules="rules"
                @submit.prevent="signUp"
              >
                <div
                  class="mb-5 what_type"
                  :class="{ is_arabic: ar_lang == true }"
                >
                  <h2 class="card-title text-center mb-3">
                    {{ publicLocalization("Register") }}
                    <span class="h3 text-muted">( Family )</span>
                  </h2>
                </div>
                <el-container>
                  <el-row :gutter="40">
                    <!-- //? First Name  -->
                    <el-col :sm="12">
                      <el-form-item
                        :label="`${publicLocalization('First Name')}`"
                        prop="first_name"
                        style="width: 100%"
                        :class="{ is_arabic: ar_lang == true }"
                      >
                        <el-input
                          type="text"
                          v-model="register.first_name"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!--//? Last Name -->
                    <el-col :sm="12">
                      <el-form-item
                        :label="`${publicLocalization('Last Name')}`"
                        prop="last_name"
                        :class="{ is_arabic: ar_lang == true }"
                        label-width="120px"
                        style="width: 100%"
                      >
                        <el-input
                          type="text"
                          v-model="register.last_name"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- //? Email  -->
                    <el-col :sm="12">
                      <el-form-item
                        :label="`${publicLocalization('Email')}`"
                        prop="email"
                        :rules="[
                          {
                            required: true,
                            message: 'Please input email address',
                            trigger: 'blur'
                          },
                          {
                            type: 'email',
                            message: 'Please input correct email address',
                            trigger: ['blur', 'change']
                          }
                        ]"
                        label-width="120px"
                        style="width: 100%"
                        :class="{ is_arabic: ar_lang == true }"
                      >
                        <el-input
                          type="email"
                          v-model="register.email"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- //? Age  -->
                    <el-col :sm="6">
                      <el-form-item
                        :label="`${publicLocalization('Age Range')}`"
                        label-width="120px"
                        prop="age_group_id"
                        :rules="[
                          {
                            required: true,
                            message: 'Please Select Age',
                            trigger: 'blur'
                          }
                        ]"
                        :class="{ is_arabic: ar_lang == true }"
                        style="width: 100%"
                      >
                        <el-select
                          v-model="register.age_group_id"
                          class="select-default"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in ageList"
                            :value="item.value"
                            :label="item.label"
                            :key="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <!-- //? Language  -->
                    <el-col :sm="6">
                      <el-form-item
                        :label="`${publicLocalization('Languages')}`"
                        label-width="120px"
                        prop="language"
                        :class="{ is_arabic: ar_lang == true }"
                        :rules="[
                          {
                            required: true,
                            message: 'Please Select Language',
                            trigger: 'blur'
                          }
                        ]"
                        style="width: 100%"
                      >
                        <el-select
                          v-model="register.language"
                          class="select-default"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in $store.getters['language/languages']"
                            :value="item.id"
                            :label="item.title"
                            :key="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <!-- //? Password  -->
                    <el-col :sm="12">
                      <el-form-item
                        :label="`${publicLocalization('password')}`"
                        prop="password"
                        :class="{ is_arabic: ar_lang == true }"
                        class="text-capitalize"
                        :rules="[
                          {
                            required: true,
                            message: 'Please input password',
                            trigger: 'blur'
                          }
                        ]"
                        label-width="120px"
                        style="width: 100%"
                      >
                        <el-input
                          type="password"
                          v-model="register.password"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- //? Confirm Password  -->
                    <el-col :sm="12">
                      <el-form-item
                        :label="`${publicLocalization('Confirm Password')}`"
                        prop="confirm_password"
                        :rules="[
                          {
                            required: true,
                            message: 'Please input confirm password',
                            trigger: 'blur'
                          }
                        ]"
                        label-width="180px"
                        style="width: 100%"
                        :class="{ is_arabic: ar_lang == true }"
                      >
                        <el-input
                          type="password"
                          v-model="register.confirm_password"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- //? City  -->
                    <el-col :sm="6">
                      <el-form-item
                        :label="`${publicLocalization('City')}`"
                        prop="city"
                        :rules="[
                          {
                            required: true,
                            message: 'Please input city',
                            trigger: 'blur'
                          }
                        ]"
                        style="width: 100%"
                      >
                        <el-input
                          type="text"
                          v-model="register.city"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!--//? State  -->
                    <el-col :sm="6">
                      <el-form-item
                        :label="`${publicLocalization('State')}`"
                        prop="state"
                        :rules="[
                          {
                            required: true,
                            message: 'Please input state',
                            trigger: 'blur'
                          }
                        ]"
                        style="width: 100%"
                        :class="{ is_arabic: ar_lang == true }"
                      >
                        <el-input
                          type="text"
                          v-model="register.state"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- //? Postal code  -->
                    <el-col :sm="6" :label="$t('login.zip_code')">
                      <el-form-item
                        :label="`${publicLocalization('Zip Code')}`"
                        :class="{ is_arabic: ar_lang == true }"
                        style="width: 100%"
                        prop="zip_code"
                      >
                        <el-input
                          type="text"
                          v-model="register.zip_code"
                          style="width: 100%"
                          class="just_switch_inarabic"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- //? Country  -->
                    <el-col :sm="6">
                      <el-form-item
                        :label="`${publicLocalization('Country')}`"
                        prop="country"
                        :rules="[
                          {
                            required: true,
                            message: 'Please input country',
                            trigger: 'blur'
                          }
                        ]"
                        :class="{ is_arabic: ar_lang == true }"
                        style="width: 100%"
                      >
                        <el-select
                          v-model="register.country"
                          filterable
                          class="select-default"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in countries"
                            :value="item"
                            :label="item"
                            :key="item"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <div>
                      <el-col :sm="24">
                        <el-form-item
                          label="Family Type"
                          prop="family_type"
                          :rules="[
                            {
                              required: true,
                              message: 'Please input family type',
                              trigger: 'blur'
                            }
                          ]"
                          :class="{ is_arabic: ar_lang == true }"
                          style="width: 100%"
                        >
                          <el-radio v-model="register.family_type" label="1"
                            >Family Admin</el-radio
                          >
                          <el-radio v-model="register.family_type" label="2"
                            >Family Member</el-radio
                          >
                        </el-form-item>
                      </el-col>
                    </div>
                    <!-- End  this condition for family type == admin family family form  to choose individual or semester-->
                    <div v-if="register.family_type == 1">
                      <el-col :sm="12">
                        <el-form-item
                          :label="`${publicLocalization('Card Name')}`"
                          prop="card_name"
                          :rules="[
                            {
                              required: true,
                              message: 'Please input card name',
                              trigger: 'blur'
                            }
                          ]"
                          label-width="120px"
                          style="width: 100%"
                          :class="{ is_arabic: ar_lang == true }"
                        >
                          <el-input
                            type="text"
                            v-model="register.card_name"
                            style="width: 100%"
                            class="just_switch_inarabic"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :sm="12">
                        <el-form-item
                          :label="`${publicLocalization('Card Number')}`"
                          prop="card_number"
                          :rules="[
                            {
                              required: true,
                              message: 'Please input card number',
                              trigger: 'blur',
                              min: 16
                            }
                          ]"
                          label-width="120px"
                          style="width: 100%"
                          :class="{ is_arabic: ar_lang == true }"
                        >
                          <el-input
                            type="text"
                            v-model="register.card_number"
                            style="width: 100%"
                            maxlength="16"
                            class="just_switch_inarabic"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :sm="12">
                        <el-form-item
                          :label="`${publicLocalization('Expiration Date')}`"
                          prop="exp_time"
                          :rules="[
                            {
                              required: true,
                              message: 'Please input Expiration date',
                              trigger: 'blur'
                            }
                          ]"
                          label-width="120px"
                          style="width: 100%"
                          :class="{ is_arabic: ar_lang == true }"
                        >
                          <el-date-picker
                            v-model="register.exp_time"
                            type="month"
                            format="MM/yy"
                            value-format="yyyy-MM"
                            :picker-options="datePickerOptions"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :sm="12">
                        <el-form-item
                          :label="`${publicLocalization('CVC Code')}`"
                          prop="cvc_code"
                          :rules="[
                            {
                              required: true,
                              message: 'Please input CVC Code',
                              trigger: 'blur',
                              min: 3
                            }
                          ]"
                          label-width="120px"
                          style="width: 100%"
                          :class="{ is_arabic: ar_lang == true }"
                        >
                          <el-input
                            type="text"
                            v-model="register.cvc_code"
                            style="width: 100%"
                            maxlength="3"
                            class="just_switch_inarabic"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </div>
                    <!-- End  this condition for family type == admin family family form  to choose individual or semester-->

                    <!--  this condition for family type == member family family form -->
                    <div v-if="register.family_type == 2">
                      <el-col :sm="12">
                        <el-form-item
                          label="Family Code"
                          prop="family_code"
                          :rules="[
                            {
                              required: true,
                              message: 'Please input Family Code',
                              trigger: 'blur'
                            }
                          ]"
                          label-width="120px"
                          style="width: 100%"
                          :class="{ is_arabic: ar_lang == true }"
                        >
                          <el-input
                            type="text"
                            v-model="register.family_code"
                            style="width: 100%"
                            class="just_switch_inarabic"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </div>
                    <!-- //? Recaptcha -->

                    <el-col>
                      <vue-recaptcha
                        sitekey="6LeJUj8dAAAAACRZPIbZ49EttmOXRX4XRE2guTx3"
                        @verify="validateCaptcha"
                      ></vue-recaptcha>
                    </el-col>
                  </el-row>
                </el-container>
                <!-- Action Buttons  -->
                <div class="d-flex justify-content-around mt-3 mb-3">
                  <el-button
                    type=""
                    class="lgin-btn"
                    @click="signUp"
                    :disabled="disableSubmit"
                  >
                    {{ publicLocalization("Create Account") }}
                  </el-button>
                </div>
              </el-form>
            </div>
            <!-- //// END THIS JUST FOR FAMILY -->
          </fade-render-transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { FadeRenderTransition } from "src/components/index";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "login",
  data() {
    return {
      isThinkific: false,
      isLetMeLearn: false,
      register: {},
      registerFormCheck: false,
      Registerdialog: true,
      inistitutionForm: false,
      studentform: false,
      familyForm: true,
      disableSubmit: true,
      rules: {
        first_name: [
          {
            required: true,
            message: "Please input First Name ",
            trigger: "change"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please input Last Name ",
            trigger: "change"
          }
        ],
        zip_code: [
          {
            required: true,
            message: "Please input zip code",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      },
      ageList: [
        {
          value: 1,
          label: `${
            this.publicLocalization("Small People") != false
              ? this.publicLocalization("Small People")
              : "6-12 years old"
          }`
        },
        {
          value: 2,
          label: `${
            this.publicLocalization("Young People") != false
              ? this.publicLocalization("Young People")
              : "13 -21 years old"
          }`
        },
        {
          value: 3,
          label: `${
            this.publicLocalization("Old People") != false
              ? this.publicLocalization("Old People")
              : "22 or older"
          }`
        }
      ],
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia ",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory ",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands ",
        "Central African Republic ",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands ",
        "Colombia",
        "Comoros ",
        "Congo ",
        "Cook Islands ",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic ",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands  [Malvinas]",
        "Faroe Islands ",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories ",
        "Gabon",
        "Gambia ",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands ",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands ",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger ",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands ",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands ",
        "United States of America ",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ],
      isArabic: false,
      selectedLanguageId: null
    };
  },
  components: {
    FadeRenderTransition,
    VueRecaptcha
  },
  mounted() {
    // get langauges available languages for user language set
    this.$store.dispatch("language/getLanguages");
    this.$store.dispatch("cms/getLocalizationForRegister", {
      id: null
    });
    // if (this.$i18n.locale == "ar") {
    //   this.isArabic = true;
    // } else {
    //   this.isArabic = false;
    // }
  },
  computed: {
    ar_lang() {
      return this.isArabic;
    },
    publishedLanguages() {
      return this.$store.getters["language/languages"];
    }
  },

  methods: {
    toggle() {
      this.registerFormCheck = !this.registerFormCheck;
    },
    validateCaptcha(response) {
      // we are not checking if captcha is right, we just check if it is clicked
      if (response.length > 0) this.disableSubmit = false;
    },

    signUp() {
      if (this.register.family_type == 1) {
        this.register.type = "individual";
      }

      this.$refs["register_Ref"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/register", {
              query: this.register,
              contact: this.$route.params.contact,
              team: this.$route.params.team,
              token: this.$route.params.token
            })
            .then(res => {
              this.$router.push("/edit-questionnaire");
            });
        }
      });
    },

    switchs(local) {
      this.$i18n.locale = local;
    },
    // getpublicLocalizationForRegister
    switchLocalization() {
      this.$store
        .dispatch("cms/getEveryLocalizationForRegisterPages", {
          language: this.selectedLanguageId
        })
        .then(() => {
          this.publishedLanguages.find(item => {
            if (item.id == this.selectedLanguageId) {
              console.log(item.rtl);
              // item.rtl == 1 ? (this.isArabic = true) : this.isArabic == false;
              if (item.rtl == 1) {
                this.isArabic = true;
              } else {
                this.isArabic = false;
              }
            }
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.thinkific_wrapper {
  display: flex;
  justify-content: center;
  .thinkific_span {
    cursor: pointer;
    width: fit-content;
  }
  .thinkific {
    color: #23ccef;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600&display=swap");
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: url("/static/img/full-screen-image-4.jpg") no-repeat center;
  background-size: cover;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
  @media (min-width: 320px) and (max-width: 400px) {
    padding: 8px;
  }
}

::v-deep .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

::v-deep label {
  margin-bottom: 0 !important;
}

::v-deep .el-input__inner {
  @media (min-width: 320px) and (max-width: 350px) {
    max-width: 275px;
    margin: auto;
  }
}
::v-deep .el-input {
  @media (min-width: 320px) and (max-width: 350px) {
    max-width: 275px;
    // margin: auto;
  }
  // max-width: 275px;
}
.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 50px 20px 50px;
  position: relative;
  @media (min-width: 320px) and (max-width: 400px) {
    padding: 20px 10px 20px 10px;
  }
  .goo-back {
    background-color: #00563f;
    position: absolute;
    width: 30px;
    height: 30px;
    // top: 0;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    z-index: 20;
    cursor: pointer;
  }
}

.price {
  font-size: 14px;
}
/* .logoo {
  width: 130px;
  height: 100px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px;
} */
// .logoo {
//   width: 300px;
//   height: 220px;
// }
.drop-language {
  display: flex;
  flex-direction: row-reverse;
}
.media {
  &.is_arabic {
    direction: rtl;
  }
  .media-body {
    &.is_arabic {
      text-align: right;
      margin-right: 1rem;
    }
  }
}
.drow-down {
  width: 100px !important;
  text-align: center;
  .el-dropdown-menu__item {
    padding: 0;

    span {
      width: 100%;
      display: block;
      transition: 0.3s all ease-in-out;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
    }
  }
}
.all_prices {
  &.is_arabic {
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}
.lgin-btn {
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  background-color: #00563f;
  // background: #00563f;
  border: none;
  outline: none;
  color: #ffff;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 1;
  }
}
// ? asterisks
::v-deep {
  .el-form-item {
    .el-form-item__label {
      width: 100%;
      text-align: left;
      &::before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
      }
    }
    &.is_arabic {
      .el-form-item__label {
        text-align: right;
        font-family: "Cairo", sans-serif;
      }
      .el-form-item__content {
        .just_switch_inarabic {
          direction: rtl;
        }
      }
    }
  }
}
.what_type {
  &.is_arabic {
    direction: rtl;
    font-family: "Cairo", sans-serif;
  }
}
.main_buttons_types {
  &.is_arabic {
    font-family: "Cairo", sans-serif;
    flex-direction: row-reverse;
  }
}
.submit_lci_buttons {
  background: #00563f;
}

$width: 25%;
.login_what_patterns {
  .text_patterns_box {
    width: 80%;
    margin: auto;
    // background: aqua;
    text-align: center;
    font-size: 2.7rem;
    font-weight: bold;
    text-transform: uppercase;
    border: 5px solid transparent;
    // border-left-color: #39c;
    // border-right-color: #c36;
    color: #777;
    position: relative;
    @media (min-width: 700px) and (max-width: 991px) {
      width: 100%;
      font-size: 2.3rem;
    }
    @media (min-width: 460px) and (max-width: 700px) {
      width: 100%;
      font-size: 1.3rem;
    }
    @media (min-width: 320px) and (max-width: 460px) {
      width: 100%;
      font-size: 0.9rem;
    }
    // pat_text
    &::before {
      position: absolute;
      content: "";
      left: 0;
      height: 100%;
      width: 5px;
      background-color: #39c;
    }
    &::after {
      position: absolute;
      content: "";
      right: 0;
      height: 100%;
      width: 5px;
      background-color: #c36;
    }

    .pat_one {
      position: absolute;
      top: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }

    .pat_two {
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }
    .pat_three {
      position: absolute;
      top: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }
    .pat_four {
      position: absolute;
      bottom: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }

    .pat_five {
      position: absolute;
      top: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }
    .pat_six {
      position: absolute;
      bottom: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }

    .pat_seven {
      position: absolute;
      top: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
    .pat_eight {
      position: absolute;
      bottom: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
  }
}
</style>
